<template>
  <div class="jumbotron p-5 rounded-lg" style="margin-bottom: 2em">
    <h1 class="display-4">Meet our library board!</h1>
  </div>

  <div class="container container-small text-center">
    <div class="col">
      <div class="row">
        <table class="table table-hover">
          <tbody>
          <tr>
            <td>President</td>
            <td>Carl Bruse</td>
          </tr>
          <tr>
            <td>Vice President</td>
            <td>Sharon King</td>
          </tr>
          <tr>
            <td>Treasurer</td>
            <td>Samantha McKenzie</td>
          </tr>
          <tr>
            <td>Secretary</td>
            <td>Pamela Perry</td>
          </tr>
          <tr>
            <td>Trustee</td>
            <td>James Murphy</td>
          </tr>
          <tr>
            <td>Trustee</td>
            <td>Diane Raths</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>

  <div class="container text-center">
      <p>The library board meets the first Monday of every month, September through May, at 5:00 pm.
        <br>The meetings are held at the library and the public is invited and welcome to attend.
        <br>
      </p>
  </div>

</template>

<script>
export default {
  name: 'libraryBoard'
}
</script>

<style scoped>
.jumbotron {
  /*background-color: #4e6738;*/
  background-color: #7a9c4e;
  color: #FFFFFF;
  text-align: center;
}

@media (min-width: 768px) {
  .container-small {
    width: 300px;
  }

}
@media (min-width: 992px) {
  .container-small {
    width: 500px;
  }

}
@media (min-width: 1200px) {
  .container-small {
    width: 700px;
  }

}

.container-small, {
  max-width: 100%;
}
</style>
